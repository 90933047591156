import React from 'react';
import Layout from '../components/layout4';
import SEO from '../components/seo';
import About from '../containers/faq';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="ジオグラフのFAQページ"
        description="ジオグラフとは地図上にグラフを表示したものです。"
      />

      <About />
    </Layout>
  );
};


export default AboutPage;
