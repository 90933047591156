import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import {
  AboutWrapper,
  AboutPageTitle,
} from './style';

import styles from './index.module.css';


const User = props => (
  <div>
      <div className={styles.user}>
        <img src={props.titleimg} className={styles.titleimg} alt="" />
        <div className={styles.faqblock}>
            <div>
              質問
            <p className={props.question}>{props.question}</p>
            </div>
            <div>
              回答
            <p className={styles.answer}>{props.answer}</p>
            </div>
        </div>
        <Link to={props.url}>
          関連資料
        </Link>
      </div>
  </div>
  )


interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      titleimg: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      
      <AboutPageTitle>
        <h2>よくあるご質問とその回答</h2>
        <p>
          このページでは、よくある質問とその回答をご紹介します。
        </p>
      </AboutPageTitle>

      <User url="/sdgs" question="	このサイトを授業で使ってもよいですか？	" titleimg="https://source.unsplash.com/featured/?earth" answer="はい、自由にお使いいただけます。
高等学校の新設科目「地理総合」や「地理探究」における、地域を展望したり、国土像を構想したりする学びなどに、ご活用いただければ幸いです。
また、文部科学省「社会科、地理歴史科、公民科の指導におけるICTの活用について」にて例示されている、“複数資料の重ね合わせ（を活かした地域分析）”に役立つツールとなれば幸いです。" />
<User url="/sdgs" question="	夏休みの課題のテーマに使ってもよいですか？	" titleimg="https://source.unsplash.com/featured/?earth" answer="はい、もちろんお使いいただけます。
高等学校の新設科目「総合的な探究の時間」などに、ご活用いただければ幸いです。" />
<User url="/sdgs" question="	こちらのサイトの画像を、資料として使ってもよいですか？	" titleimg="https://source.unsplash.com/featured/?earth" answer="教育やまちづくりが目的であれば、自由にお使いいただけます。
ただし、資料には「地域見える化GISジオグラフ（©帝国書院＋都市計画可視化推進機構）」のウェブサイトを参照していることを明示してください。
教育やまちづくり以外の目的でご利用になりたい場合は、以下までお問い合わせください。
＊帝国書院のお問い合わせページのリンクを貼付？" />
<User url="/sdgs" question="	３Dグラフが描画されている画面の、背景の地図の出典を教えてください。	" titleimg="https://source.unsplash.com/featured/?earth" answer="	国土地理院から提供されているものになります。	" />
<User url="/sdgs" question="	３DグラフをGoogleEarthで表示させた際に、背景を地理院地図に替えることはできますか？	" titleimg="https://source.unsplash.com/featured/?earth" answer="	可能です。地理院地図のサイトからKMLファイルをダウンロードし、GoogleEarthPro上で読み込むと、レイヤーの一つとして重ねることができます。	" />
<User url="/sdgs" question="	分析をより深めるためにはどうしたらよいですか？	" titleimg="https://source.unsplash.com/featured/?earth" answer="	メッシュ統計データは様々な機関で整備と公開が進められております。当サイトの３Dグラフを描画するページの下方に、「データの詳細情報を確認する」コーナーがあり、そちらの外部リンクが参考の一つになるでしょう。	" />
<User url="/sdgs" question="	複数の市町村の３Dグラフを、一つの画面上で同時に描画させたいのですが。	" titleimg="https://source.unsplash.com/featured/?earth" answer="「GoogleEarthで表示」ボタンをご活用いただくことで、可能になります。
当サイトでは、３Dグラフを描画するページ内の右上側に「GoogleEarthで表示」ボタンがあり、各市町村ごとの３Dグラフ描画結果をKMLファイルとしてダウンロードすることができます。それらをGoogleEarthPro上で呼び出し、いずれをもチェックボックスでonにすれば、同一画面上で合わせて描画されることとなります。" />
<User url="/sdgs" question="	県全体の３Dグラフを、一つの画面上で同時に描画させたいのですが。	" titleimg="https://source.unsplash.com/featured/?earth" answer="	当サイト上で可能です。市町村名の入力欄に、都道府県名をご入力ください。県全体の描画が一気に開始されます。ただし、お使いのデバイスのスペック、なかでもメモリの量によっては、描画が遅くなったりフリーズしたりする恐れもございますので、ご留意ください。	" />
<User url="/sdgs" question="	日本全国の３Dグラフを、一つの画面上で同時に描画させたいのですが。	" titleimg="https://source.unsplash.com/featured/?earth" answer="	当サイト上で可能です。３Dグラフを描画させるページ内の右上側に、「同じテーマを国土全体で見る」タブがございます。そちらを選ぶと、自動的に日本全国の３Dグラフ描画が開始されます。こちらは、描画が重たくならないよう、メッシュの大きさを広げてデータを軽くしてあります。	" />
<User url="/sdgs" question="	見られるデータが少ないようですが？	" titleimg="https://source.unsplash.com/featured/?earth" answer="	令和4年4月には、全国の高等学校で新課程の学びが始まりますが、それまでに順次、SDGsや地理総合・地理探究などのテーマ沿ったデータを、広く整えていきたいと考えています。	" />
<User url="/sdgs" question="	「場所を確認する」ボタンを押しても、だいぶ離れた場所が表示されるのですが。	" titleimg="https://source.unsplash.com/featured/?earth" answer="	こちらの機能は、視点の位置の直下の座標をGoogleMapで呼び出すものとなっております。真下を向いてからか、場所を確認したい地点にズームアップしてから、ボタン押下してみてください。	" />
<User url="/sdgs" question="	各メッシュの範囲がどのような地域か、もっとスムーズに見られるとよいのですが。	" titleimg="https://source.unsplash.com/featured/?earth" answer="「GoogleEarthで表示」ボタンをご活用いただくことで、よりスムーズな把握が可能になります。
こちらから、描画した３DグラフをKMLファイルでダウンロードし、GoogleEarthPro上で呼び出すことができます。GoogleEarthProでは、各メッシュへ視点を近づけることで、その地点のGoogleストリートビューを見られます。
なお、３Dマウスを用いると、より快適な把握が可能になるでしょう。" />
<User url="/sdgs" question="	載せてほしいデータがあるのですが？	" titleimg="https://source.unsplash.com/featured/?earth" answer="是非ご連絡ください。
当サイトは、1㎞メッシュに加工されたメッシュデータであれば、広く取り扱うことができます。重ね合わせると面白いデータの組み合わせを、ドシドシご紹介いただければ幸いです。
＊推進機構さまのお問い合わせページのリンクを貼付？" />
<User url="/sdgs" question="	「典型例とその解説」ですが、自分の家の近所に、まさにそうした地域がございます。	" titleimg="https://source.unsplash.com/featured/?earth" answer="是非ご連絡ください。
地理の教科書で太ゴシックで記されていたり、ニュースの話題になったりしているような社会現象が、より鮮明に３Dグラフに現れる地域に心当たりがございましたら、是非ドシドシご紹介いただければ幸いです。
＊帝国書院のお問い合わせページのリンクを貼付？" />
<User url="/sdgs" question="	改善してもらいたい点があるのですが。	" titleimg="https://source.unsplash.com/featured/?earth" answer="是非お寄せください。
基本的にボランティアによる運営のため、一つ一つのご意見にお返事することは難しいのですが、改善のアイデアはいつでも歓迎しております。
機能面や取扱データの改善点につきましては、こちらからよろしくお願いいたします。
＊推進機構さまのお問い合わせページのリンクを貼付？
地理学習に関わる改善点につきましては、こちらよろしくお願いいたします。
＊帝国書院のお問い合わせページのリンクを貼付？" />
<User url="/sdgs" question="	こちらのサイトは、どういった経緯でつくられたのですか？	" titleimg="https://source.unsplash.com/featured/?earth" answer="地方自治体の都市計画の現場で活用されているWebGIS「都市構造可視化計画」が、A県G市で開催された地域活性化ワークショップのなかで紹介されておりました。そこに居合わせた、地図帳や地理の教科書出版社である帝国書院の社員が、「都市構造可視化計画」の3次元グラフによるクロス分析機能に、惚れ込んだのがきっかけです。
一般的なGISでは、データの重ね合わせを人間が認識する際には、結局のところ2次元平面上のデータ分布をアナログ的に目視することになります。
しかしながら、こちらの3次元グラフは、「色」と「高さ」の表現を駆使することで、重ね合わせをデジタル処理し、二つの要素が有意に相互作用しているエリアを強調表現することができます。
このことは、市民によるデータ利活用を発展させていくための教材として、大きな可能性を持っていると感じられました。
当サイトの、学習指導要領に挙げられている内容に即したデータの整備や、ユーザーインターフェイスの改善などは、「都市構造可視化計画」の開発に携わるNPO団体である都市構造可視化推進機構の方々および、筑波大学連携大学院の石井儀光准教授、神奈川大学の根元一幸特任准教授による多大なご尽力により出来上がっております。" />



    </AboutWrapper>
  );
};

export default About;
